.info{
    position: relative;
    padding: 13px 15px;
    height: 106px;
    font-size: 18px;
    line-height: 26px;
    color:#000;
    cursor: pointer;
    background: #fff;
    margin-top: 20px;
  .title{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .footer{
    position: absolute;
    left: 15px;
    bottom: 11px;
    font-size: 14px;
    color: #CCCCCC;
  }
  &:hover{
    .title{
      color: #1D8526;
    }
  }
}


@primary-color: #0f8605;@border-radius-base: 2px;