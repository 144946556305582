.bodyWarp{
  display: flex;

  .leftWarp{
    width: 810px;
    margin-right: 30px;
    .searchWrap{
      background: #EEEEEE;
      padding: 0 20px;
      margin-bottom: 20px;
      .searchItem{
        margin: 0;
        padding:14px 0 9px 0;
        border-bottom: 1px dotted #ddd;
        &:last-child{
          border-bottom:none;
        }
      }
    }

    .listWrap{
      flex:1;
      overflow: hidden;
      background:#fff;
      margin-bottom: 20px;
      .tabs{
        display: flex;
        font-size: 16px;
        border-bottom: 1px solid #DDDDDD;
        .tab{
          position: relative;
          cursor: pointer;
          line-height: 42px;
          margin-right: 30px;
        }
        .active{
          font-weight: bold;
          color: #645ECD;
          .border{
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            border-radius: 1px;
            background: #645ECD;
            width: 100%;
          }
        }
      }
      .title{
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
        i{
          font-style: normal;
          margin: 0 10px;
          font-size: 16px;
          cursor: pointer;
          &.active{
            color: #645ECD;
          }
        }
      }
      .more{
        width: fit-content;
        color: #666;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        padding-top: 30px;
        margin: auto;
        span{
          cursor: pointer;
        }
        &:hover{
          border-bottom: 1px solid #666;
        }
      }
    }

  }
  .rightWarp{
    width: 348px;
    .bannerWarp{
      cursor: pointer;
      margin-top: 30px;
      img{
        height: 247px;
        width: 100%;
      }
    }
  }
}






@primary-color: #0f8605;@border-radius-base: 2px;